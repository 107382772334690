// Libraries.
import styled from "styled-components"
import { Link } from "gatsby"

import { dark100, yellow, bold } from "../../shared/StyleConstants"

export const H2 = styled.h2`
    margin-bottom: 50px;
`;

export const P = styled.p`
    margin-bottom: 50px;
    line-height: 30px;
`;

export const Ul = styled.ul`
    margin: 0 0 50px 60px;
`;

export const Li = styled.li`
    margin-bottom: 6px;
    line-height: 25px;
`;

export const StyledLink = styled(Link)`
    color: ${yellow};
    text-decoration: none;
    font-weight: ${bold};
    display: flex;
    align-items: center;
    gap: 10px
`;

export const Nav = styled.nav`
    background-color: ${dark100};
    padding: 40px 0;
    margin-bottom: 150px;
    position: sticky;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1000;
`;
