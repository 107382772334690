// Libraries.
import styled from "styled-components"

export const Container = styled.div`
  position: relative;
  padding: 0 20px;
  margin: 0;
  width: 100%;
  @media (min-width: 360px) {
  }
  @media (min-width: 375px) {
  }
  @media (min-width: 768px) {
    margin: 0 auto;
    max-width: 680px;
  }
  @media (min-width: 1120px) {
    max-width: 1080px;
  }
  @media (min-width: 1280px) {
    max-width: 1120px;
  }
  @media (min-width: 1792px) {
    max-width: 1120px;
  }
`
export const Flex1 = styled.div`
  flex: 1;
`

export const Flex0 = styled.div`
  flex: 0;
`
