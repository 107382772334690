// Libraries.
import { createGlobalStyle } from "styled-components"

// Styles
import "./reset.css"
import {
  dark,
  text,
  title1,
  title2,
  title3,
  title4,
  fontFamily,
} from "./StyleConstants"

export const GlobalStyle = createGlobalStyle`
  html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    box-sizing: border-box;
    scroll-behavior: smooth !important;
    overflow: ${props => (props.removeOverflow ? "hidden" : "visible")};
  }
  body {
    overflow: ${props => (props.removeOverflow ? "hidden" : "visible")};
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: hsla(0, 0%, 0%, 0.8);
    word-wrap: break-word;
    font-kerning: normal;
    -moz-font-feature-settings: "kern", "liga", "clig", "calt";
    -ms-font-feature-settings: "kern", "liga", "clig", "calt";
    -webkit-font-feature-settings: "kern", "liga", "clig", "calt";
    font-feature-settings: "kern", "liga", "clig", "calt";
    font-family: ${fontFamily};
    font-size: ${text};
    color: ${dark};
  }
  * {
    box-sizing: inherit;
  }
  *:before {
    box-sizing: inherit;
  }
  *:after {
    box-sizing: inherit;
  }
  h1 {
    font-size: ${title1};
    line-height: 50px;
  }
  h2 {
    font-size: ${title2};
    line-height: 58px;
  }
  h3 {
    font-size: ${title3};
  }
  h4 {
    font-size: ${title4};
    line-height: 44px;
  }
`
