// Fonts.
export const fontFamily = "Arial, Helvetica, sans-serif"
export const text = "17px"
export const title1 = "70px"
export const title2 = "50px"
export const title3 = "40px"
export const title4 = "30px"
export const regular = "400"
export const bold = "700"

// Colors.
/* Colors */
export const dark = "#000000"
export const white = "#ffffff"
export const dark100 = "#0C1000"
export const yellow = "#BAFF00"
export const blueish = "#B4CAD2"

// Opacities
export const opacity20 = "33"
export const opacity40 = "66"
export const opacity60 = "99"
export const opacity86 = "DB"