// Libraries.
import React from "react"
import { Link } from "gatsby"

// Styles.
import { FooterB } from "./styles"
import { StyledLink } from "./styles"
import { Flex } from "./styles"

// Shared Components.
import { Container } from "../../shared/Container"

// Assets.
import linkedin from "./assets/linkedin-icon.svg"

const Footer = () => {
  return (
    <FooterB>
      <Container>
        <Flex>
          <a href="https://www.linkedin.com/in/diatredu/" target="_blank">
            <img src={linkedin} alt="logo linkedin" />
          </a>
          <StyledLink to="/privacy">Privacy Policy</StyledLink>
        </Flex>
      </Container>
    </FooterB>
  )
}

export default Footer
