// Libraries.
import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

//Shared Components.
import { Container } from "../components/shared/Container"
import { GlobalStyle } from "../components/shared/GlobalStyles"

// Styles.
import { Nav } from "../components/sections/Privacy/styles"
import { StyledLink } from "../components/sections/Privacy/styles"

// Assets.
import arrowBack from "../components/sections/Privacy/assets/arrow-back.svg"

// Components.
import PrivacySection from "../components/sections/Privacy/Privacy"
import Footer from "../components/sections/Footer/Footer"

const Privacy = () => (
  <>
    <GlobalStyle />
    <Nav>
      <Container>
        <StyledLink to="/">
          <img src={arrowBack} alt="Arrow back" />
          BACK TO DIEGOTREJO.COM
        </StyledLink>
      </Container>
    </Nav>
    <PrivacySection />
    <Footer />
  </>
)

export default Privacy
