// Libraries.
import styled from "styled-components"
import { Link } from "gatsby"

import { dark, white, bold } from "../../shared/StyleConstants"

export const FooterB = styled.footer`
    background-color: ${dark};
    color: ${white};
    padding: 30px 0;
`;

export const P = styled.p`
    color: ${white};
    font-weight: ${bold};
    text-decoration: none;
`;

export const StyledLink = styled(Link)`
    color: ${white};
    font-weight: ${bold};
    text-decoration: none;
`;

export const Flex = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;